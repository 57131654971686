<template>
  <div class="coianter">
    <div class="flexrow tiecg">
      <div class="tilelft">新增申报</div>
      <div class="tieright">
        <el-button type="primary" @click="goback">返回</el-button>
      </div>
    </div>
    <div class="table main-box">
      <!-- {{ form }} -->
      <el-form ref="form" :label-position="labelPosition" :model="form" label-width="150px" :rules="rules">
        <div class="jiben">
          <el-row>
            <el-col :span="8">
              <el-form-item label="标准名称" prop="proName">
                <el-input v-model="form.proName" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="标准类型" prop="biaozhunType">
                <el-radio-group v-model="form.biaozhunType">
                  <el-radio :label="1">标准</el-radio>
                  <el-radio :label="2">标准设计</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="制定类型" prop="zhidingType">
                <el-radio-group v-model="form.zhidingType">
                  <el-radio :label="1">制定</el-radio>
                  <el-radio :label="2">修订</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="企业名称" prop="firstUnit">
                <el-input v-model="form.firstUnit" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="地址" prop="firstAddress">
                <el-input v-model="form.firstAddress" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="邮编">
                <el-input v-model="form.postcode" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="企业所在省">
                <el-select v-model="form.city1_id" placeholder="所在省、直辖市或自治区" @change="cityChange">
                  <el-option v-for="item in cities" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="企业所在市">
                <el-select v-model="form.city2_id" placeholder="所在市">
                  <el-option v-for="item in countyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>



          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="企业法定代表人" prop="firstHandler" :rules="{
                required: true,
                message: '请填写企业法定代表人',
                trigger: 'blur',
              }">
                <el-input v-model="form.firstHandler" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话(手机)" prop="firstTel">
                <el-input v-model="form.firstTel" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电子邮箱">
                <el-input v-model="form.firstEmail" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item label="联系人" prop="firstProContact">
                <el-input v-model="form.firstProContact" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话(手机)" prop="firstProContactTel">
                <el-input v-model="form.firstProContactTel" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电子邮箱">
                <el-input v-model="form.firstProContactEmail" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item label="企业简介" prop="content">
              <el-input v-model="form.content" style="width: 100%" type="textarea" placeholder="请输入企业简介" maxlength="500"
                show-word-limit> </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="应用工程1" >
              <el-input v-model="form.project1" style="width: 100%" placeholder="请输入应用工程"
              > </el-input>
            </el-form-item>
            <el-form-item label="应用工程2" >
              <el-input v-model="form.project2" style="width: 100%" placeholder="请输入应用工程"
              > </el-input>
            </el-form-item>
            <el-form-item label="应用工程3">
              <el-input v-model="form.project3" style="width: 100%" placeholder="请输入应用工程"
              > </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <div class="flexrow lefttie">
              <div class="ltleft">
                资料上传
                <a :href="downloadLink" download="初稿审查资料模板" style="color: #3086fb;  font-size: 12px">
                  <el-button type="danger" round size="mini">{{ checkdata.templete.name }}<i
                      class="el-icon-bottom"></i></el-button>
                </a>
              </div>
            </div>
            <div class="leftfile">
              <div class="filelistcg">
                <div v-for="(item, index) in checkdata.uploadfiles" class="cgfiles" :key="index">
                  <div class="flexrow cgtop">
                    <div class="cgfleft">
                      <div>
                        {{ item.name }}
                        <el-tag v-if="item.isupload" effect="dark" size="mini" type="danger">必须上传</el-tag>
                      </div>
                    </div>
                    <div class="cgfrig">
                      <el-upload accept=".pdf,.jpg,.png,.doc,.docx" :before-upload="showLoad"
                        :data="{ token: $store.state.token }" :file-list="item.fileList"
                        :on-success="(response) => changeMaterImg(item, response)" :show-file-list="false"
                        :on-remove="(file, fileList) => removeImg(item, file, fileList)" action="api/public/bupload"
                        class="upload-demo">
                        <el-button size="small" type="primary" :disabled="!isValueInList">上传文件 </el-button>
                      </el-upload>
                    </div>
                  </div>

                  <div class="flexrow cgtop" v-for="(file, fileIndex) in item.fileList" :key="fileIndex">
                    <div class="cgfleft">
                      <div>
                        <!-- {{ file.url }} -->
                        <div style="color: #999999;cursor: pointer;" class="filename" @click="viewFile(file)">
                          {{ file.name }}
                        </div>
                      </div>
                    </div>
                    <div class="cgfrig" style="color: #3086fb;cursor: pointer;" @click="delbtn(item, fileIndex)">删除
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="comit">
      <div class="baocun">
        <el-button type="primary" @click="saveProject()">暂时保存</el-button>
      </div>
      <div class="baocun">
        <el-button type="success" @click="tijiaoProject()">提交</el-button>
      </div>
    </div>

    <!-- 模态框 -->
    <el-dialog title="文件预览" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
      <div v-if="selectedFile">
        <p><strong>文件名称: </strong>{{ selectedFile.name }}</p>
        <p><strong>文件URL: </strong><a :href="selectedFile.url" target="_blank">{{ selectedFile.url }}</a></p>

        <!-- 图片预览 -->
        <img v-if="isImage(selectedFile.url)" :src="selectedFile.url" alt="文件图片预览"
          style="max-width: 100%; margin-top: 20px;" />

        <!-- PDF 预览 -->
        <div v-else-if="isPDF(selectedFile.url)" style="margin-top: 20px;">
          <embed :src="selectedFile.url" type="application/pdf" width="100%" height="600px" />
        </div>

        <!-- 其他文件类型提示 -->
        <p v-else style="margin-top: 20px;">无法预览此类型的文件。</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import { getBiaozhunDetail, addBiaozhun, userInfoApi, enterpriseFileDel } from "@api/project";
import { cityOne, countryData } from "@api/enterprise";

export default {
  data() {
    return {

      disagreelist: [undefined, '1.40', '2.20', '2.40', '2.60'], //当这里面数值时可以提交数据
      labelPosition: "right",
      dialogImageUrl: "",
      dialogVisible: false,
      selectedFile: null,
      disabled: false,
      fileList: [],
      form: {
        proName: "",
        biaozhunType: 1,
        USname: "",
        zhidingType: 1,
        firstUnit: "",
        firstAddress: "",
        firstHandler: "",
        firstTel: "",
        firstEmail: "",
        content: "",
        postcode: "",
        token: "",
        firstProContact: "",
        firstProContactEmail: "",
        firstProContactTel: "",
        type: "1",
        files: [],
        city1_id: "",
        city2_id: "",
        project1:"",
        project2:"",
        project3:"",

      },
      cities: "",
      countyList: [],
      // 审核资料数据结构
      checkdata: {
        "stage": "formalCheck",
        "stagetypeid": '1',
        "templete": {
          "name": "模板下载",
          "file": "https://api.gbservice.cn/storage/uploads/standard/5ae5061594f0899cec0c7392580a5492.zip",
          "delaytime": 8
        },
        "uploadfiles": [
          {
            "orderid": 1,
            "name": "江苏省工程建设企业技术标准/标准设计认证承诺书",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 2,
            "name": "江苏省工程建设企业技术标准/标准设计认证委托书",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 3,
            "name": "企业营业执照及相关资质证书",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 4,
            "name": "企业技术标准/标准设计批准发布文件",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 5,
            "name": "企业技术标准/标准设计审查意见和审查人员名单表",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 6,
            "name": "修订说明",
            "isupload": false,
            "fileList": []
          },
          {
            "orderid": 7,
            "name": "江苏省工程建设企业技术标准/标准设计文本（含条文说明）",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 8,
            "name": "编制说明、执行相关标准情况说明、研究报告和有关设计计算书等",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 9,
            "name": "企业标准信息公共服务平台发布的企业标准",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 10,
            "name": "有资质的检测机构出具的性能检测报告",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 11,
            "name": "企业技术标准/标准设计中涉及或未涉及专利的声明",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 12,
            "name": "工程建设、设计、施工、监理等单位联合签证的在省内2个以上工程应用证明或验收报告",
            "isupload": true,
            "fileList": []
          }
        ]
      },
      // 规则限制
      rules: {
        example_project: [
          {
            required: true,
            message: '请填写应用工程信息',
            trigger: 'blur',
          }
        ],
        city1: [
          {
            required: true,
            message: '请选择省',
            trigger: 'blur',
          }
        ],
        city2: [
          {
            required: true,
            message: '请选择市',
            trigger: 'blur',
          }
        ],
        proName: [
          {
            required: true,
            message: '请填写标准名称',
            trigger: 'blur',
          }
        ],
        firstAddress: [
          {
            required: true,
            message: '请填写地址',
            trigger: 'blur',
          }
        ],
        postcode: [{
          required: true,
          message: '请填写邮编',
          trigger: 'blur',
        },
        { min: 6, max: 6, message: '长度在6位数字', trigger: 'blur' }
        ],
        firstUnit: [
          {
            required: true,
            message: '请填写单位',
            trigger: 'blur',
          }
        ],
        firstTel: [
          {
            max: 11,
            required: true,
            message: '请填写正确的联系电话',
            trigger: 'blur',
          }
        ],
        firstEmail: [
          {
            type: 'email',
            required: true,
            message: '请填写正确的电子邮箱',
            trigger: 'blur',
          }
        ],
        firstProContact: [{
          required: true,
          message: '请填写联系人',
          trigger: 'blur',
        }],
        firstProContactTel: [
          {
            required: true,
            message: '请填写联系电话',
            trigger: 'blur',
          }
        ],
        firstProContactEmail: [
          {
            type: 'email',
            required: true,
            message: '请填写正确的电子邮箱',
            trigger: 'blur',
          }
        ],
        content: [{
          required: true,
          message: '请填写企业介绍',
          trigger: 'blur',
        }]
      }
    };
  },
  computed: {
    downloadLink() {
      return this.checkdata.templete.file;
    },
    isValueInList() {
      return this.disagreelist.includes(this.formStatus);
    },
    // 增加一个通过制定和修订联动修编说明的方法


  },
  watch: {
    // 自动更新编制类型
    'form.zhidingType'(newValue) {
      if (newValue === 2) {
        this.$set(this.checkdata.uploadfiles[5], 'isupload', true);
      } else {
        this.$set(this.checkdata.uploadfiles[5], 'isupload', false);
      }
    }

  },
  mounted() {

    this.getToken();
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getInfo();
    }
    this.getuserinfo();
    this.getCityOne()
  },
  methods: {
    // 获取一级城市
    getCityOne() {
      cityOne()
        .then((res) => {          
          this.cities = res.data
        })
        .catch((err) => {          
          this.$message.error(err.message);
        });
    },
    //市区筛选
    cityChange() {
      this.getDistrict(this.form.city1_id)
      this.form.city2_id = ""
    },
    getDistrict(pid) {
      let params = {
        parent_id: pid
      }
      countryData(params)
        .then((res) => {
          this.countyList = res.data
        })
        .catch((err) => {
          //console.log(err)
          this.$message.error(err.message);
        });
    },
    // 判断是否为PDF类型
    isPDF(fileUrl) {
      return /\.pdf$/i.test(fileUrl);
    },
    // 判断是否为图片类型
    isImage(fileUrl) {
      return /\.(jpg|jpeg|png|gif)$/i.test(fileUrl);
    },
    viewFile(file) {
      this.selectedFile = file;
      this.dialogVisible = true;
    },
    // 关闭模态框前的处理
    handleClose(done) {
      // 可以在这里添加关闭前的逻辑，如清理资源
      this.selectedFile = null;
      done();
    },

    changeMaterImg(item, response) {

      var uploadedFile = {
        name: response.data.name,
        url: response.data.url,
        orderid: item.orderid,
        type: "1",
        stageid: "1",
      };
      item.fileList.push(uploadedFile);

      this.form.files.push(uploadedFile);

    },
    removeImg(item, file, fileList) {
      const index = fileList.findIndex(f => f === file);

      this.$confirm(`是否确认删除文件 ${file.name}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        fileList.splice(index, 1);
      }).catch(() => {

      });
    },
    // 删除上传的图片
    delbtn(item, fileIndex) {

      // 当已经提交状态不允许删除
      if (this.isValueInList) {
        const fileToRemove = item.fileList[fileIndex];
        this.$confirm(`是否确认删除文件 ${fileToRemove.name}？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          item.fileList.splice(fileIndex, 1);
          let param = {
            token: this.$store.state.token,
            id: fileToRemove.id,
            isdelete: 1
          }

          enterpriseFileDel(param)
            .then((res) => {
              this.$message.success("删除成功！");
            })
            .catch((err) => {
              this.$message.warning(err);
            });
        }).catch(() => {
          this.$message("删除失败");
        });
      } else {
        // 审查中的状态不给删除
        this.$message.warning("当前为审查中状态，不能被删除!", "警告")
      }
    },

    // 上传之前进行格式和文件大小限制
    showLoad(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = ["pdf", "jpg", "png", "doc", "docx"];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message("上传文件只能是 pdf、jpg、png、doc、docx格式", "error");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 16;
      if (!isLt2M) {
        this.$message("上传文件大小不能超过 16MB", "error");
      }
      return isLt2M;
    },

    goback() {
      this.$router.go(-1);
    },

    getToken() {
      this.token = this.$store.state.token; //获取cookie字符串
    },

    //获取详情
    getInfo() {
      getBiaozhunDetail({ id: this.id, token: this.token })
        .then((res) => {
          this.htmlTitle = res.data.proName;
          this.form = res.data;
          this.form.ren = res.data.fu;

        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取用户信息
    getuserinfo() {
      let param = {
        token: this.token
      };
      userInfoApi(param).then((res) => {
        // this.userdata = res.data;
        let organdata = res.data.organizations;
        this.form.firstUnit = organdata.name;
        this.form.firstAddress = organdata.address;
        this.form.firstEmail = organdata.email;
        this.form.content = organdata.introduction;
        this.form.postcode = organdata.postcode;
        this.form.firstHandler = organdata.legal_representative;
        this.form.firstTel = organdata.telephone;
        this.form.firstProContact = this.$store.state.user.name;
        this.form.firstProContactTel = this.$store.state.user.telephone;
        this.form.firstProContactEmail = this.$store.state.user.email;

      }).catch((err) => {
        console.log(err);
      });
    },
    //暂存保存
    saveProject() {

      this.form.status = 1.15; //保存状态
      this.form.token = this.token;

      addBiaozhun(this.form).then((rec) => {
        this.$message({
          type: "success",
          message: "临时保存成功!",
        });

      });
    },
    //提交项目
    tijiaoProject() {
      this.$confirm("确定是否提交企业标准认证信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.form.status = 1.20; //保存状态
        this.form.type = "1";
        this.form.token = this.token;
        this.$refs.form.validate((valid) => {
          if (valid) {
            addBiaozhun(this.form).then((rec) => {
              this.$message({
                type: "success",
                message: "提交成功!",
              });
              this.$router.push({
                path: "/user_project/itemIdent",
              });
            });
          } else {

            this.$message({
              showClose: true,
              message: "请填写完整信息",
              type: "warning",
            });
            return false;
          }
        });
      });
    }
  },
};
</script>
<style scoped>
.arrow-disabled {
  color: #c0c4cc;
  cursor: not-allowed;
}

.inputDeep>>>.el-input__inner {
  border: 0;
  font-weight: bold;
}

/deep/.el-input.is-disabled .el-input__inner {
  background-color: #a5a7ab;
  border-color: #a5a7ab;
  color: #fff;
}

/deep/.el-button--primary {
  background-color: #3082e2;
  border-color: #3082e2;
}

/deep/.el-button--primary:hover {
  background-color: #409eff;
  border-color: #409eff;
}

/deep/ .el-input {
  width: 250px;
}
</style>
<style lang="scss" scoped>
.coianter {
  background-color: #ffffff;
  height: 90vh;
  overflow: hidden;
  overflow-y: scroll;
  // height: 100%;
}

.coianter::-webkit-scrollbar {
  display: none;
}

.idteail {
  margin: 4px;
  width: 100%;
  //   height: 50px;
  line-height: 30px;
  background-color: #f4f4f4f4;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  .idtname {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    margin-left: 63px;
  }

  .back {
    margin-right: 23px;
    font-size: 20px;
  }
}

.gezi {
  // margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .geziright {
    // margin-left: 20px;
  }

  .fileright {
    display: flex;
    flex-direction: row-reverse;
    color: #3086fb;
    font-size: 14px;
    cursor: pointer;
  }
}

.table {
  margin: 30px 50px;
}

.comit {
  padding: 94px 0;
  width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .baocun {
    margin: 0 25px;
  }
}

.name_input {
  width: 100%;
}

.name_input>.el-form-item {
  width: 100%;
}

.name_input>.el-form-item .el-input {
  width: 100%;
}

.flexrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
  height: 60px;
  line-height: 60px;
  margin-bottom: 20px;
}

.tilelft {
  padding-left: 20px;
}

.tieright {
  padding-right: 20px;
}
</style>
